import React, { FC } from 'react'
import Layout from '@/containers/Layout'
import SearchContainer from '@/containers/SearchContainer'
import { useGlobalStrings } from '@/lib/useGlobalStrings'
import { SeoSettings } from 'web/types/custom-graphql-types'

interface SearchPageProps {}

const SearchPage: FC<SearchPageProps> = (props) => {
  const i18n_refs = [
    {
      slug: {
        current: 'search'
      },
      _type: 'page',
      i18n_lang: 'de'
    }
  ]

  const { searchPageSeoTitle, searchPageSeoDescription } = useGlobalStrings()

  const seoSettings: SeoSettings = {
    seoTitle: searchPageSeoTitle?.en,
    seoDescription: searchPageSeoDescription?.en
  }

  return (
    <Layout
      title="Search"
      _type="page"
      i18n_lang="en"
      i18n_refs={i18n_refs}
      seoSettings={seoSettings}
    >
      <SearchContainer i18n_lang="en" />
    </Layout>
  )
}
export default SearchPage
